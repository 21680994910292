import {
  useParams,
  createAsync,
  type RouteSectionProps,
  type RouteDefinition,
} from "@solidjs/router";
import { getProductData } from "~/dataFunctions";
import { useProduct } from "~/services/products/useProduct";

export const route = {
  load: ({ params }) => useProduct(params.sku),
} satisfies RouteDefinition;

export default function DefaultProduct(props: RouteSectionProps) {
  const params = useParams();

  const prod = createAsync(() => useProduct(params.sku));

  return (
    <div class="m-4 p-4 !border !border-green-500">
      This is GALLERY FRAME SPECIFIC content
      <p>SKU is: {params.sku}</p>
      <p>SKU is: {props.params.sku}</p>
      <p>KEY ? {getProductData.keyFor(params.sku)}</p>
      <pre class="whitespace-pre-wrap text-sm text-orange-400 border border-orange-400">
        {JSON.stringify(prod(), null, 4)}
      </pre>
    </div>
  );
}
