import { createEffect, Show } from "solid-js";
import {
  type RouteSectionProps,
  type RouteDefinition,
  createAsync,
} from "@solidjs/router";
import { MetaProvider, Title } from "@solidjs/meta";
import { getBuilderPageData } from "~/services/builder";
import { Content } from "@builder.io/sdk-solid";
import { builderComponents } from "~/utils/builder/componentList";

// TODO
// ? do the components need to be registered somewhere? reminder for Monday.

export const route = {
  load: ({ params }) => {
    // throw an error in here if builder data is empty? or redirect?
    // or display a NotFound component below?
    return getBuilderPageData(`/${params.index}`);
    // TODO: redirect or show notfound component
  },
} satisfies RouteDefinition;

export default function Home(props: RouteSectionProps) {
  const data = createAsync(() => getBuilderPageData(props.location.pathname));

  return (
    <div>
      <Show when={data()}>
        <Title>
          {data()?.data?.title && props.location.pathname !== "/"
            ? `${data()?.data?.title} | `
            : ""}
          Roma Moulding
        </Title>
        <Content
          model="page"
          apiKey={import.meta.env.VITE_BUILDER_APIKEY}
          content={data()}
          customComponents={builderComponents}
        />
      </Show>
    </div>
  );
}
