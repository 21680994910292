import { createEffect, createMemo } from "solid-js";
import {
  createAsync,
  useParams,
  type RouteDefinition,
  type RouteSectionProps,
} from "@solidjs/router";
import { getProductData } from "~/dataFunctions";
import { useProduct, useProductPricing } from "~/services/products";
import { useSiteContext } from "~/utils/contexts";
import { ThresholdStatus, stockThreshold } from "~/utils/threshold";
import { ProductPriceResponse } from "~/services/roma-api/types";
import { Product } from "~/components/product";
import { PHOTOFRAME, GALLERYFRAME, MOULDING, MIRROR } from "~/utils/products";

export const route = {
  load: ({ params }) => useProduct(params.sku),
} satisfies RouteDefinition;

export default function ProductLanding(props: RouteSectionProps) {
  const { global } = useSiteContext();
  const params = useParams();
  const product = createAsync(async () => useProduct(params.sku));
  // TODO > isPartner/session check here?

  type FormattedPricing = ProductPriceResponse & {
    availableAs?: string[];
    defaultPlantLowStock?: boolean;
    anyPlantLowStock?: boolean;
  };
  const pricing = createAsync(async () => {
    const data: FormattedPricing = await useProductPricing(params.sku);

    // console.log("DATA", await data)

    // const data = await response.json();
    data.availableAs = Object.keys(data.Pricing);

    //       // setting 2 properties (defaultPlantLowStock / anyPlantLowStock) that will
    //       // show and hide the stock subscription button:
    data.defaultPlantLowStock = [
      ThresholdStatus.lowStock,
      ThresholdStatus.zeroStock,
    ].includes(
      stockThreshold(
        data.Inventory[data.Plant],
        product()?.Category,
        product()?.Discontinued
      ).status
    );

    const checkAllPlants = () => {
      if (data.defaultPlantLowStock) return true;

      for (const item of Object.entries(data.Inventory)) {
        const status: ThresholdStatus = stockThreshold(
          item[1] as number,
          product()?.Category as string,
          product()?.Discontinued
        ).status;

        if (
          [ThresholdStatus.lowStock, ThresholdStatus.zeroStock].includes(status)
        ) {
          return true;
        }
      }
      return false;
    };
    data.anyPlantLowStock = checkAllPlants();

    return data;
  });

  const type = createMemo(() => {
    if (props.location.pathname.includes("photo-frame")) {
      return PHOTOFRAME;
    } else if (props.location.pathname.includes("gallery-frame")) {
      return GALLERYFRAME;
    } else if (props.location.pathname.includes("mirror")) {
      return MIRROR;
    } else {
      return MOULDING;
    }
  });

  const suggestionList = createMemo(() => {
    if (product() && product()?.Suggestions) {
      const arr = product()?.Suggestions.reduce((memo, item) => {
        memo.push({
          type: type(),
          data: item,
        });
        return memo;
      }, []);
      return arr;
    }})

  createEffect(() => console.log("PRICING FROM [SKU]", pricing()));

  return (
    <div>
      This is the Produt Layout - everything here is shared amongst all /product
      pages..SKU is {params.sku}
      <div>
        "Outlet" is below in red:
        <div class="border-2 border-red-500">{props.children}</div>
      </div>
    </div>
  );
}
