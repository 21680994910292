import { type ParentComponent, createSignal, createEffect } from "solid-js";
import { useIsRouting, createAsync } from "@solidjs/router";
import { SiteContext, SessionContext } from "~/utils/contexts";
import { createScrollableNav } from "~/utils/scroll";
import { createBreakpoints } from "@solid-primitives/media";
import { serializeProducts } from "~/utils/products";
import createAnalytics from "@solid-primitives/analytics";
import { trackGTAG, trackFBQ } from "~/utils/analytics";
import { usePrefersDark } from "@solid-primitives/media";
import { VERSION } from "~/app";
import { getBuilderGlobalData } from "~/services/builder";

// ! DUMMIES

const session: Record<string, string> = {
  name: "TESTNAME",
  token: "TESTTOKEN",
  favourites: "",
  recentlySearched: "",
};

const setSession = (key: string, val: string | undefined) => {
  console.log("TEST - setSession >> ", key, val);
};

const isPartner = () => false;

const addToRecentlySearched = (value: string) => {
  console.log("TEST - addToRecentlySearch >> ", value);
  // if (!value || value === "") return;
  // const arr = JSON.parse(session.recentlySearched) || [];
  // let location = -1;
  // // see if value already exists
  // for (const i in arr) {
  //   if (arr[i] === value) {
  //     location = parseInt(i);
  //     break;
  //   }
  // }
  // // it does not exist, unshift
  // if (location === -1) {
  //   arr.unshift(value);
  // } else {
  //   // remove the existing value and unshift
  //   arr.splice(location, 1);
  //   arr.unshift(value);
  // }

  // const trimmed = arr.slice(0, 5);
  // setSession("recentlySearched", JSON.stringify(trimmed));
};

const clearRecentlySearched = () => {
  console.log("TEST - clearRecentlySearch");
};

const isDark = usePrefersDark();
const [isDarkMode, setIsDarkMode] = createSignal(isDark());

export const SiteContextProviderAndSessionManager: ParentComponent = (
  props
) => {
  return (
    <SessionContext.Provider
      value={{
        session,
        setSession,
        isPartner,
        addToRecentlySearched,
        clearRecentlySearched,
      }}
    >
      <SiteContextProvider>{props.children}</SiteContextProvider>
    </SessionContext.Provider>
  );
};

const SiteContextProvider: ParentComponent = (props) => {
  const headerVisible = createScrollableNav();
  const isLoading = useIsRouting();
  const breakpoints = createBreakpoints({
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    xxl: "1536px",
  });
  const track = createAnalytics([trackGTAG]);

  const global = createAsync(()=> getBuilderGlobalData())

  return (
    <SiteContext.Provider
      value={{
        headerVisible,
        isLoading,
        breakpoints,
        track,
        trackFBQ,
        toggleFavourite,
        VERSION,
        isDarkMode,
        setIsDarkMode,
        global
      }}
    >
      {props.children}
    </SiteContext.Provider>
  );
};

const toggleFavourite = (sku: string, type = "moulding") => {
  setSession(
    "favourites",
    serializeProducts(session.favourites, sku, type, "toggle")
  );
};
